<template>
  <div class="template center_phone">
    <template v-if="bindPhoneNum">
      <div class="mod_left">
        {{ bindPhoneNum }}
      </div>
      <div class="mod_right">
        <ui_btn_2 :width="'2.2rem'" :height="'0.38rem'" @click="showConfirmTag = 2">{{
          lang == "en" ? "CHANGE PHONE NUMBER" : "更換手機號碼"
        }}</ui_btn_2>
      </div>
    </template>
    <template v-else>
      <div class="mod_left">
        <div class="key">Tel</div>
        <div class="red no">Unbound mobile phone number</div>
      </div>
      <div class="mod_right">
        <ui_btn_1
          :width="'1.28rem'"
          :height="'0.38rem'"
          @click="showConfirmTag = 1"
          v-if="userInfo && userInfo.uid"
          >{{ lang == "en" ? "bind phone" : "綁定手機" }}</ui_btn_1
        >
      </div>
    </template>
  </div>
  <ui_confirm_3
    :code_type="showConfirmTag == 1?5:7"
    v-if="showConfirmTag"
    @cancle="showConfirmTag = 0"
    @confirm="bindPhone"
    :type="'warn'"
    class="confirm"
  >
    <template v-slot:header>{{
      showConfirmTag == 1 ? "綁定手機號碼" : "更換手機號碼"
    }}</template>
    <template v-slot:txt>
      {{
        showConfirmTag == 1 ? "請輸入您要綁定的手機號碼。" : "請輸入您要更換的手機號碼。"
      }}
    </template>
  </ui_confirm_3>
</template>
<script setup>
import { getCurrentInstance, computed, onMounted, watch, reactive, ref } from "vue";
import { useStore } from "vuex";
import ui_btn_1 from "@/components/ui/ui_btn_1";
import ui_btn_2 from "@/components/ui/ui_btn_2";
import ui_confirm_3 from "./ui_confirm_3.vue";
import common from "../../utils/common";
import axios from "axios";
let store = useStore();
let lang = computed(() => {
  return store.state.lang;
});
let userInfo = computed(() => {
  console.log("userInfo", store.state.userInfo);
  return store.state.userInfo;
});

let bindPhoneNum = computed(() => {
  return store.state.phone_num;
});

const showConfirmTag = ref(0); //1：綁定手機；2:更換手機
const bindPhone = (d) => {
  console.log(d);
  let T;
  if (common.isMobilePhone(d[0])) {
    T = showConfirmTag.value;
  } else {
    toastr["info"](lang=='en'?"請輸入手機號碼":"Please enter your phone number");
    return;
  }
  const sendUrl =
    common.userApi.requestUrl +
    "/user_api/accounts/" +
    (showConfirmTag.value == 1 ? d[0] : userInfo.value.email) +
    "/bind_infos";
  let sendData = {
    verify_code: d[1],
    type: showConfirmTag.value == 1 ? 1 : 2, //type賬號類型，1為手機號碼，2為郵箱
  };
  if(showConfirmTag.value == 2){    
    sendData.new_account =  d[0],
    sendData.new_account_type = 1
  }
  sendData = common.buildSendData(sendData);
  common.showLoading("bindPhone");
  axios
    .post(sendUrl, sendData)
    .then((r) => {
      if (r.data.code == 0) {
        toastr["success"](r.data.msg);
        showConfirmTag.value = 0;
        //退出重登錄
        common.loginout('cneter_phone 100');
        common.showLogin();
        store.commit("userHubStore/set_showUserHub", true);
        toastr["info"](lang=='en'?"請重新登錄":"Please log in again");
      } else {
        toastr["error"](r.data.msg);
      }
    })
    .catch()
    .finally(function () {
      common.hideLoading("bindPhone");
      common.queryUserBindInfo();
      console.log("that");
    });
};
</script>
<style lang="scss" scoped>
.center_phone {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.red {
  margin-top: 0.2rem;
}
.confirm {
}
</style>
