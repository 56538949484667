<template>
  <div class="template center">
    <div :class="{ left: true, hideinmobile: showMobileDetailTag }">
      <div class="avatar">
        <img
          v-if="userInfo && userInfo.head_url"
          :src="userInfo.head_url"
          alt=""
          @click="showMobileDetailTag = !showMobileDetailTag"
        />
        <span v-else class="img"></span>
        <ui_btn_2
          v-if="userInfo && sysConfig"
          :width="'0.62rem'"
          @click="confirmEditAvatar"
          :height="'0.33rem'"
        >
          {{ langTxt[lang].center[0] }}
        </ui_btn_2>
        <div class="onlymobile" @click="showMobileDetailTag = !showMobileDetailTag">
          <div class="nick">
            {{ userInfo ? userInfo.nick : "-"
            }}<svg
              width="14"
              height="15"
              viewBox="0 0 14 15"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M4.83882 11.4126C5.06663 11.6404 5.43598 11.6404 5.66378 11.4126L9.37609 7.70033L5.66378 3.98802C5.43598 3.76021 5.06663 3.76021 4.83882 3.98802C4.61102 4.21583 4.61102 4.58517 4.83882 4.81298L7.72618 7.70033L4.83882 10.5877C4.61102 10.8155 4.61102 11.1848 4.83882 11.4126Z"
                fill="white"
                fill-opacity="0.5"
              />
            </svg>
          </div>
          <span class="uid">{{ userInfo ? userInfo.uid : "-" }}</span>
        </div>
        <div class="onlymobile mywallet">
          <router-link to="u">
            <svg
              width="64"
              height="43"
              viewBox="0 0 64 43"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clip-path="url(#clip0_2750_146066)">
                <rect
                  y="0.5"
                  width="64"
                  height="42"
                  rx="5"
                  fill="white"
                  fill-opacity="0.2"
                />
                <path
                  d="M39.4286 21C39.4286 22.0585 38.5971 22.9167 37.5714 22.9167C36.5458 22.9167 35.7143 22.0585 35.7143 21C35.7143 19.9415 36.5458 19.0833 37.5714 19.0833C38.5971 19.0833 39.4286 19.9415 39.4286 21Z"
                  fill="white"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M43.1429 15.25C44.1685 15.25 45 16.1081 45 17.1667V24.8333C45 25.8919 44.1685 26.75 43.1429 26.75V30.5833C43.1429 31.6419 42.3114 32.5 41.2857 32.5H20.8571C19.8315 32.5 19 31.6419 19 30.5833V11.4167C19 10.3581 19.8315 9.5 20.8571 9.5H41.2857C42.3114 9.5 43.1429 10.3581 43.1429 11.4167V15.25ZM21.7857 12.375V29.625H40.3571V26.75H32C30.9743 26.75 30.1429 25.8919 30.1429 24.8333V17.1667C30.1429 16.1081 30.9743 15.25 32 15.25H40.3571V12.375H21.7857ZM32.9286 18.125V23.875H42.2143V18.125H32.9286Z"
                  fill="white"
                />
              </g>
              <defs>
                <clipPath id="clip0_2750_146066">
                  <rect y="0.5" width="64" height="42" rx="5" fill="white" />
                </clipPath>
              </defs>
            </svg>
          </router-link>
        </div>
      </div>
      <div class="remaining">
        <p>{{ langTxt[lang].center[1] }}</p>
        <span class="val"
          ><img
            src="https://cdn.blockwar.io/web_assets/img/ico_exchange_gold.png"
            alt=""
          />
          {{ userFinance.goldCoins }}</span
        >
      </div>
      <div class="coins" v-if="userFinance && userFinance.silverCoins > 0">
        <p>{{ langTxt[lang].center[2] }}</p>
        <span class="val"
          ><img
            src="https://cdn.blockwar.io/web_assets/img/ico_exchange_sliver.png"
            alt=""
          />
          {{ userFinance.silverCoins }}</span
        >
      </div>
      <div class="gold">
        <p>{{ langTxt[lang].center[3] }}</p>
        <span class="val">{{ userFinance.goldMedal }}</span>
      </div>
      <div class="sliver">
        <p>{{ langTxt[lang].center[4] }}</p>
        <span class="val">{{ userFinance.silverMedal }}</span>
      </div>
    </div>
    <div :class="{ right: true, showinmobile: showMobileDetailTag }">
      <div
        :class="{ back: true, onlymobile: true }"
        @click="showMobileDetailTag = !showMobileDetailTag"
      >
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M15.7071 18.7071C15.3166 19.0976 14.6834 19.0976 14.2929 18.7071L7.92893 12.3431L14.2929 5.97919C14.6834 5.58866 15.3166 5.58866 15.7071 5.97919C16.0976 6.36971 16.0976 7.00287 15.7071 7.3934L10.7574 12.3431L15.7071 17.2929C16.0976 17.6834 16.0976 18.3166 15.7071 18.7071Z"
            fill="white"
          />
        </svg>
      </div>
      <div>
        <h2 class="mortina">{{ langTxt[lang].center[7] }}</h2>
        <div class="info mod">
          <div class="onlymobile" @click="confirmEditAvatar">
            <span class="key">Avatar</span
            ><span class="val"
              ><img
                :src="userInfo.head_url"
                v-if="userInfo && userInfo.head_url"
                alt=""
              /><svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M8.29289 18.7071C8.68342 19.0976 9.31658 19.0976 9.70711 18.7071L16.0711 12.3431L9.70711 5.97919C9.31658 5.58866 8.68342 5.58866 8.29289 5.97919C7.90237 6.36971 7.90237 7.00287 8.29289 7.3934L13.2426 12.3431L8.29289 17.2929C7.90237 17.6834 7.90237 18.3166 8.29289 18.7071Z"
                  fill="white"
                  fill-opacity="0.5"
                />
              </svg>
            </span>
          </div>
          <div>
            <span class="key">{{ langTxt[lang].center[5] }}</span>
            <span class="val onlypc"
              >{{ userInfo ? userInfo.nick : "-" }}
              <ui_btn_2
                v-if="userInfo && sysConfig"
                :width="'0.62rem'"
                :height="'0.33rem'"
                @click="confirmEditNick"
              >
                {{ langTxt[lang].center[0] }}</ui_btn_2
              >
            </span>
            <span class="val onlymobile" @click="confirmEditNick"
              >{{ userInfo ? userInfo.nick : "-" }}
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M8.29289 18.7071C8.68342 19.0976 9.31658 19.0976 9.70711 18.7071L16.0711 12.3431L9.70711 5.97919C9.31658 5.58866 8.68342 5.58866 8.29289 5.97919C7.90237 6.36971 7.90237 7.00287 8.29289 7.3934L13.2426 12.3431L8.29289 17.2929C7.90237 17.6834 7.90237 18.3166 8.29289 18.7071Z"
                  fill="white"
                  fill-opacity="0.5"
                />
              </svg>
            </span>
          </div>
          <div>
            <span class="key">{{ langTxt[lang].center[6] }}</span
            ><span class="val">{{ uid || "-" }}</span>
          </div>
        </div>
      </div>
      <div>
        <h2 class="mortina">{{ langTxt[lang].center[8] }}</h2>
        <div class="mod account">
          <div>
            <div class="key">{{ langTxt[lang].center[9] }}</div>
            <div>
              <span :class="{ red: !userInfo || !userInfo.email, val: true }">{{
                userInfo
                  ? userInfo.email || langTxt[lang].center[14]
                  : langTxt[lang].center[14]
              }}</span>
            </div>
          </div>
          <div class="btn_reset" v-if="userInfo && userInfo.email">
            <ui_btn_2 :width="'1.67rem'" :height="'0.48rem'" @click="resetPW">{{
              langTxt[lang].center[10]
            }}</ui_btn_2>
          </div>
          <div v-else-if="!userInfo">
            <ui_btn_1
              v-if="!uid"
              :width="'1.67rem'"
              :height="'0.48rem'"
              @click="loginAndGetuid"
              >{{ langTxt[lang].center[15] }}
            </ui_btn_1>
          </div>
          <div v-else-if="!userInfo.email">
            <ui_btn_1 :width="'1.67rem'" @click="showCompleteWalletUI" :height="'0.48rem'"
              >{{ langTxt[lang].center[16] }}
            </ui_btn_1>
          </div>
        </div>
        <div class="mod phone">
          <center_phone />
        </div>
        <div class="mod wallet">
          <div>
            <div class="key">{{ langTxt[lang].center[11] }}</div>
            <div class="data">
              <span :class="{ red: !walletLoginName }">{{
                walletLoginName ? walletLoginName : langTxt[lang].center[13]
              }}</span>
              <ui_btn_1
                v-if="!walletLoginName && !uid"
                @click="loginAndGetuid"
                :width="'1.67rem'"
                :height="'0.48rem'"
              >
                {{ langTxt[lang].center[17] }}</ui_btn_1
              >
              <ui_btn_1
                v-else-if="!walletLoginName"
                :width="'1.67rem'"
                :height="'0.48rem'"
                @click="bindWalletUI"
              >
                {{ langTxt[lang].center[12] }}</ui_btn_1
              >
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="input_nick" v-if="showInputNickUI">
      <div class="container">
        <input
          v-model.trim="newNickname"
          type="text"
          :placeholder="langTxt[lang].center[24]"
        />
        <div class="tips">
          <svg
            width="14"
            height="14"
            viewBox="0 0 14 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M7 0C3.136 0 0 3.136 0 7C0 10.864 3.136 14 7 14C10.864 14 14 10.864 14 7C14 3.136 10.864 0 7 0ZM7 13.006C3.689 13.006 0.994 10.311 0.994 7C0.994 3.689 3.689 0.994 7 0.994C10.311 0.994 13.006 3.689 13.006 7C13.006 10.311 10.311 13.006 7 13.006Z"
              fill="url(#paint0_linear_964_38042)"
            />
            <path
              d="M6.99979 8.10601C6.76179 8.10601 6.56579 7.92401 6.54479 7.68601L6.27879 4.39601C6.25779 4.13001 6.46779 3.90601 6.73379 3.90601H7.25879C7.52479 3.90601 7.73479 4.13001 7.71379 4.39601L7.44779 7.68601C7.43379 7.92401 7.23779 8.10601 6.99979 8.10601Z"
              fill="url(#paint1_linear_964_38042)"
            />
            <path
              d="M6.24414 9.33803C6.24414 9.53854 6.32379 9.73083 6.46557 9.8726C6.60735 10.0144 6.79964 10.094 7.00014 10.094C7.20064 10.094 7.39294 10.0144 7.53471 9.8726C7.67649 9.73083 7.75614 9.53854 7.75614 9.33803C7.75614 9.13753 7.67649 8.94524 7.53471 8.80346C7.39294 8.66168 7.20064 8.58203 7.00014 8.58203C6.79964 8.58203 6.60735 8.66168 6.46557 8.80346C6.32379 8.94524 6.24414 9.13753 6.24414 9.33803Z"
              fill="url(#paint2_linear_964_38042)"
            />
            <defs>
              <linearGradient
                id="paint0_linear_964_38042"
                x1="-0.000102426"
                y1="13.9991"
                x2="15.3135"
                y2="12.317"
                gradientUnits="userSpaceOnUse"
              >
                <stop stop-color="#FF8688" />
                <stop offset="1" stop-color="#E9373E" />
              </linearGradient>
              <linearGradient
                id="paint1_linear_964_38042"
                x1="6.27733"
                y1="8.10574"
                x2="7.86687"
                y2="8.04596"
                gradientUnits="userSpaceOnUse"
              >
                <stop stop-color="#FF8688" />
                <stop offset="1" stop-color="#E9373E" />
              </linearGradient>
              <linearGradient
                id="paint2_linear_964_38042"
                x1="6.24413"
                y1="10.0939"
                x2="7.898"
                y2="9.91227"
                gradientUnits="userSpaceOnUse"
              >
                <stop stop-color="#FF8688" />
                <stop offset="1" stop-color="#E9373E" />
              </linearGradient>
            </defs>
          </svg>
          <span> {{ langTxt[lang].center[25] }}</span>
        </div>
        <div class="btn" v-show="newNickname">
          <span @click="showInputNickUI = false">Cancel</span>
          <span @click="buyModifyNickCard" class="confirm">GO</span>
        </div>
        <div class="close" @click="showInputNickUI = false"></div>
      </div>
    </div>
    <div class="avatarlist" v-if="showAvatarListUI">
      <div class="box">
        <div class="top">
          <img :src="avatarArray[avatarIndex].url" alt="" />
        </div>
        <div class="middle">
          <scrollContainer
            :height="'3rem'"
            :slotColor="'rgba(0, 0, 0, 0)'"
            :scrollColor="'#DDDEE0'"
            :scrollWidth="5"
            @arrive="handleScroll"
          >
            <ul>
              <li
                :class="{ on: avatarIndex == inx }"
                v-for="(item, inx) in avatarArray"
                :data-index="inx"
                :data-logo="item.logo"
                :key="inx"
                @click="selectAvatar(inx)"
              >
                <img :src="item.url" alt="" />
              </li>
            </ul>
          </scrollContainer>
        </div>
        <div class="bot">
          <ui_btn_1
            class="onlymobile"
            :width="'0.86rem'"
            :height="'0.38rem'"
            @click="closeAvatarList"
            >{{ lang == "en" ? "Cancel" : "取消" }}</ui_btn_1
          >
          <ui_btn_1 :width="'0.86rem'" :height="'0.38rem'" @click="buyAvatar">{{
            lang == "en" ? "OK" : "确定"
          }}</ui_btn_1>
        </div>
        <div class="close" @click="closeAvatarList"></div>
      </div>
    </div>
  </div>
  <pagebottom></pagebottom>
</template>
<script src="./center.js"></script>
<style lang="scss" src="./center.scss"></style>
