<template>
  <i class="info" v-if="props.type == 'info'">
    <svg
      width="27"
      height="27"
      viewBox="0 0 27 27"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="13.5" cy="13.5" r="13.5" fill="#0080FE" />
      <path d="M12 10H15V19H17V21H11V19H13V12H12V10Z" fill="white" />
      <circle cx="13.5" cy="7.5" r="1.5" fill="white" /></svg
  ></i>
  <i class="info" v-else-if="props.type == 'success'">
    <svg
      width="27"
      height="27"
      viewBox="0 0 27 27"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="13.5" cy="13.5" r="13.5" fill="#0080FE" />
      <path d="M12 10H15V19H17V21H11V19H13V12H12V10Z" fill="white" />
      <circle cx="13.5" cy="7.5" r="1.5" fill="white" /></svg
  ></i>
  <i class="info" v-else-if="props.type == 'error'">
    <svg
      width="27"
      height="27"
      viewBox="0 0 27 27"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="13.5" cy="13.5" r="13.5" fill="#F93A20" />
      <path d="M12 10H15V19H17V21H11V19H13V12H12V10Z" fill="#F93A20" />
      <circle cx="13.5" cy="7.5" r="1.5" fill="#F93A20" />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M8 9.73684L9.73684 8L13.5 11.7632L17.2632 8L19 9.73684L15.2368 13.5L19 17.2632L17.2632 19L13.5 15.2368L9.73684 19L8 17.2632L11.7632 13.5L8 9.73684Z"
        fill="white"
      />
    </svg>
  </i>
  <i class="info" v-else-if="props.type == 'warn'">
    <svg
      width="27"
      height="28"
      viewBox="0 0 27 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="13.5" cy="14" r="13.5" fill="#F7B500" />
      <rect x="12.5" y="6.5" width="2" height="12" fill="white" />
      <rect x="12.5" y="19.5" width="2" height="2" fill="white" />
    </svg>
  </i>
  <i class="info" v-else-if="props.type == 'null'"></i>
  <i class="info" v-else
    ><svg
      width="27"
      height="27"
      viewBox="0 0 27 27"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="13.5" cy="13.5" r="13.5" fill="#0080FE" />
      <path d="M12 10H15V19H17V21H11V19H13V12H12V10Z" fill="white" />
      <circle cx="13.5" cy="7.5" r="1.5" fill="white" /></svg
  ></i>
</template>
<script setup>
let props = defineProps(["type"]);
</script>
<style lang="scss" scoped>
.info {
  width: 0.27rem;
  height: 0.27rem;
  svg {
    width: 100%;
    height: 100%;
  }
}
</style>
