<template>
  <div class="ui_confirm_3 disselect" :codetype="props.code_type">
    <div class="contain">
      <div class="title">
        <icon :type="props.type"></icon>
        <slot name="header">title</slot>
      </div>
      <p class="tips"><slot name="txt">txt</slot></p>

      <div class="body">
        <div class="phone">
          <input type="text" v-model="phoneORmail" placeholder="請輸入手機號碼" />
          <span class="phonetxt" v-if="phoneORmail && CD_Num == 0" @click="getVRC">{{
            lang == "en" ? "Verification Code" : "獲取驗證碼"
          }}</span>
        </div>
        <div class="phone" v-if="phoneORmail && CD_Num > 0">
          <input type="text" v-model="VRC" placeholder="請輸入驗證碼" />
          <span v-if="CD_Num > 1" class="phonetxt reget"
            >{{ lang == "en" ? "Reacquire" : "重新獲取" }} ({{ CD_Num }}s)</span
          >
          <span v-if="CD_Num == 1" class="phonetxt reget wait" @click="getVRC"
            >重新獲取</span
          >
        </div>
        <p class="red tips" v-if="phoneORmail && CD_Num > 0">{{"請輸入"+(props.code_type==5?"手機":"郵箱")+"收到的驗證碼"}}</p>
      </div>
      <div class="btn">
        <span @click="cancelFn">{{ lang == "en" ? "cancel" : "返回" }}</span>
        <span
          @click="confirmFn"
          :class="{ confirm: true, on: phoneORmail && VRC ? true : false }"
          >{{ lang == "en" ? "OK" : "提交" }}</span
        >
      </div>
      <span class="close" @click="cancelFn"></span>
    </div>
  </div>
</template>
<style lang="scss" scoped>
.ui_confirm_3 {
  position: fixed;
  z-index: 8;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba($color: #000000, $alpha: 0.3);
  display: flex;
  justify-content: center;
  align-items: center;

  .contain {
    width: 4.5rem;
    min-height: 2.27rem;
    box-sizing: border-box;
    background: #ffffff;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    padding: 0.3rem 0;
    position: relative;

    .title {
      font-size: 0.2rem;
      display: flex;
      align-items: center;
      height: 0.3rem;
      color: #333;
      padding: 0 0.3rem;
      padding-bottom: 0.1rem;
      position: relative;
      i {
        margin-right: 0.1rem;
        padding: 0;
        svg {
          widows: 0.27rem;
          height: 0.27rem;
          vertical-align: middle;
        }
      }
    }

    .tips {
      font-size: 0.16rem;
      color: rgba(51, 51, 51, 0.8);
      margin: 0 0 0 0.67rem;
    }
    .txt {
      font-size: 0.18rem;
      color: #5c5c5c;
      font-weight: 500;
      padding: 0 0.3rem;
      margin-bottom: 0.17rem;
      text-transform: capitalize;
    }
    .body {
      padding: 0.3rem;
      .tips{
        font-size: 0.12rem;
        margin: 0;
        padding: 0;
        font-weight: normal;
      }
      .phone {
        width: 100%;
        border-radius: 0.1rem;
        border: 1px solid rgba(0, 0, 0, 0.3);
        overflow: hidden;
        padding: 0 0.12rem;
        box-sizing: border-box;
        position: relative;
        margin-bottom: 0.2rem;
        input {
          width: 70%;
          height: 0.48rem;
          border: 0;
          outline: 0;
          box-sizing: border-box;
          font-size: 0.16rem;
        }
        .phonetxt {
          position: absolute;
          top: 50%;
          right: 0.12rem;
          transform: translateY(-50%);
          color: #9daaff;
          cursor: pointer;
          &.reget {
            color: rgba(0, 0, 0, 0.4);
            cursor: default;
            &.wait {
              color: #9daaff;
              cursor: pointer;
            }
          }
        }
      }
    }

    .btn {
      padding: 0 0.3rem;
      text-align: right;
      display: flex;
      justify-content: flex-end;
      align-items: center;

      span {
        display: inline-block;
        width: 0.86rem;
        height: 0.38rem;
        background-color: #bdbdbd;
        border-radius: 5px;
        font-size: 0.18rem;
        color: rgba(255, 255, 255, 0.8);
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;

        &:hover {
          background-color: #828282;
        }

        &.confirm {
          background: #3c3f52;
          margin-left: 0.2rem;
          color: #fff;
          cursor: default;
          &.on {
            cursor: pointer;
            background: var(--linearGradientRed);
            &:hover {
              background: var(--linearGradientRedHover);
            }
          }
        }
      }
    }
  }
}

.close {
  position: absolute;
  top: 0.2rem;
  right: 0.2rem;
  width: 0.4rem;
  height: 0.4rem;
  cursor: pointer;
  background: url("https://cdn.blockwar.io/web_assets/img/ico_close.png") no-repeat center
    center / 100% auto;

  &:hover {
    background-image: url("https://cdn.blockwar.io/web_assets/img/ico_close_hover.png");
  }
}
</style>
<script setup>
import axios from "axios";
import { computed, ref, onUnmounted } from "vue";
import { useStore } from "vuex";
import common from "../../utils/common";
import icon from "./icon.vue";
// import { useRouter } from "vue-router";
let store = useStore();
let lang = computed(() => {
  return store.state.lang;
});

let userInfo = computed(() => {
  console.log("userInfo", store.state.userInfo);
  return store.state.userInfo;
});

let props = defineProps({
  type: {
    default: "info",
  },
  code_type: {
    default: 5,
  },
});
let emits = defineEmits(["cancle", "confirm"]);

const cancelFn = () => {
  emits("cancle");
};
const confirmFn = () => {
  emits("confirm", [phoneORmail.value, VRC.value]);
};

let phoneORmail = ref("");
let CD_Num = ref(0);
let VRC = ref();
let getVRC_SI;
const CD60 = () => {
  CD_Num.value = 60;
  getVRC_SI = setInterval(() => {
    if (CD_Num.value > 1) {
      CD_Num.value = CD_Num.value - 1;
    } else {
      CD_Num.value = 1;
      clearInterval(getVRC_SI);
    }
  }, 1000);
};
const getVRC = () => {
  VRC.value = "";
  let apiType = props.code_type==5?"phones":"emails";
  let sendPhoneORmail = props.code_type==5?phoneORmail.value:userInfo.value.email;
  console.log('apiType',apiType);
  const sendUrl = common.userApi.requestUrl + "/user_api/"+apiType+"/" + sendPhoneORmail + "/sms";
  const sendData = common.buildSendData({
    type: props.code_type,
  });
  common.showLoading("getVRC");
  axios
    .post(sendUrl, sendData)
    .then((r) => {
      if (r.data.code == 0) {
        toastr["success"](r.data.msg);
        CD60();
        if (!common.isProduction) {
          VRC.value = r.data.debug_verify_code;
        }
      } else {
        console.error(r.data.msg);
        toastr["error"](r.data.msg);
      }
    })
    .finally(() => {
      common.hideLoading("getVRC");
    });
};
onUnmounted(() => {
  clearInterval(getVRC_SI);
});
</script>
