import { defineComponent, computed, onMounted, watch, reactive, ref } from "vue";
import { useStore } from "vuex";
// import { useRouter } from "vue-router";
import ui_btn_2 from '@/components/ui/ui_btn_2'
import ui_btn_1 from '@/components/ui/ui_btn_1'
import pagebottom from "@/components/pagebottom/pagebottom.vue";
import common from '../../utils/common.js'
import axios from "axios";
import scrollContainer from '@/components/ui/scrollContainer';
import center_phone from '@/views/center/center_phone.vue';

export default defineComponent({
    name: "",
    props: {},
    components: { ui_btn_2, ui_btn_1, pagebottom, scrollContainer, center_phone },
    setup(props, ctx) {
        // let router = useRouter();
        let store = useStore();
        let lang = computed(() => {
            return store.state.lang;
        });
        let langTxt = store.state.text;

        //用戶基本信息
        let uid = computed(() => {
            return store.state.uid
        });
        let userInfo = computed(() => {
            if (store.state.userInfo) {
                store.commit("userHubStore/set_showUserHub", false);
            }
            else {
                store.commit("userHubStore/set_showUserHub", true);
            }
            console.log('store.state.userInfo', store.state.userInfo)
            return store.state.userInfo;
        });
        let walletLoginName = computed(() => {
            return store.state.walletLoginName
        });
        //用戶財產
        let userFinance = reactive({
            goldCoins: '-',
            silverCoins: '-',
            goldMedal: '-',
            silverMedal: '-',
        })
        //拉取系統配置
        common.getSysConfig('modify_nick_card_info,modify_user_logo_card_info');
        let sysConfig = computed(() => {
            return store.state.sysConfig;
        });

        //查詢財產信息
        const getUserFinance = async () => {
            await common.queryFinance().then((r) => {
                console.log('--lb', 'getUserFinance', r)
                if (r && r.data && r.data.code == 0) {
                    for (let k in r.data.infos[0]) {
                        let res = r.data.infos[0][k];
                        for (let i = 0; i < res.length; i++) {
                            if (res[i].type == common.financeType.gold) {
                                userFinance.goldCoins = res[i].value;
                            }
                            else if (res[i].type == common.financeType.silver) {
                                userFinance.silverCoins = res[i].value;
                            }
                            else if (res[i].type == common.financeType.goldMedal) {
                                userFinance.goldMedal = res[i].value;
                            }
                            else if (res[i].type == common.financeType.silverMedal) {
                                userFinance.silverMedal = res[i].value;
                            }
                        }
                        if (res) break;
                    }
                    getLogoNickCardInfo(sysConfig);
                }
                return r;
            })
        }
        watch(userInfo, (newVal, oldVal) => {
            if (newVal) {
                getUserFinance();
            }
        })

        //登錄並獲取UID
        const loginAndGetuid = (() => {
            window.loginByWallet(['metamask'], function (r) {
                console.log('--lb', 'loginAndGetuid', r);
            }, function (e) {
                console.error('loginAndGetuid', e)
            });
        })
        //顯示補充賬號密碼UI
        const showCompleteWalletUI = () => {
            window.showCompleteWalletUI();
        }
        //顯示賬號綁定錢包UI
        const bindWalletUI = () => {
            window.bindWalletUI();
        }
        //顯示賬號修改密碼UI
        const resetPW = () => {
            store.commit('userHubStore/set_showUserRestPW', true)
        }

        //獲取修改頭像和暱稱配置
        let modifyUserLogoCardInfo = reactive({ type: 'coins', value: '' });
        let modifyNickCardInfo = reactive({ type: 'coins', value: '' });
        const getLogoNickCardInfo = () => {
            const newVal = store.state.sysConfig;
            if (newVal) {
                for (let i = 0; i < newVal.length; i++) {
                    if (newVal[i].name == 'modify_nick_card_info') {
                        modifyNickCardInfo = JSON.parse(newVal[i].value).asset_infos[0];
                        if (modifyNickCardInfo.type == 2) {
                            modifyNickCardInfo.type = langTxt[lang.value].center[20];
                        }
                    }
                    if (newVal[i].name == 'modify_user_logo_card_info') {
                        modifyUserLogoCardInfo = JSON.parse(newVal[i].value).asset_infos[0];
                        if (modifyUserLogoCardInfo.type == 2) {
                            modifyUserLogoCardInfo.type = langTxt[lang.value].center[20]
                        }
                    }
                }
                console.warn('--lb', modifyUserLogoCardInfo, modifyNickCardInfo);
            }
        }
        watch(sysConfig, (newVal, oldVal) => {
            getLogoNickCardInfo(newVal);
        })
        getLogoNickCardInfo(sysConfig);
        //拉取頭像列表 
        //滾動條https://vuescrolljs.yvescoding.me/zh/guide/optimizing-performance.html
        let avatarOffset = computed(() => {
            return avatarArray.value.length;
        });
        let getLength = 19;
        let avatarArray = ref([]);
        let showAvatarListUI = ref(false);
        let avatarIndex = ref(0);
        let canLoadAvatar = true;
        let loadingTag = false;
        const getAvatarList = () => {
            // console.warn('--lb',avatarOffset.value, canLoadAvatar, !loadingTag);
            if (avatarOffset.value <= 529 && canLoadAvatar && !loadingTag) {
                let endNum = avatarOffset.value + getLength;
                const sendUrl = common.userApi.requestUrl + '/user_api/sys_user_logo_infos?begin=' + avatarOffset.value + '&end=' + endNum;
                const sendHeader = common.buildHeaders('form');
                common.showLoading('getAvatarList');
                loadingTag = true;
                axios.get(sendUrl, { headers: sendHeader }).then((r) => {
                    if (r.data.code == 0) {
                        // console.warn('--lb',"avatarOffset", avatarOffset.val);
                        avatarArray.value = avatarArray.value.concat(r.data.infos);
                        showAvatarListUI.value = true;
                        // console.log('--lb',avatarArray.value);
                        if (r.data.infos.length == 0 || r.data.infos == 'null' || !r.data.infos) {
                            canLoadAvatar = false;
                        }
                    }
                    else {
                        canLoadAvatar = false;
                    }
                }).catch().finally(() => {
                    common.hideLoading('getAvatarList');
                    loadingTag = false;
                });
            }
        }
        //詢問修改頭像
        const confirmEditAvatar = () => {
            getUserFinance().then((r) => {
                common.confirm({
                    type: 'info',
                    title: langTxt[lang.value].center[18],
                    text: langTxt[lang.value].center[19] + modifyUserLogoCardInfo.value + ' ' + modifyUserLogoCardInfo.type,
                    btnTxt: langTxt[lang.value].confirm.btnTxt[1],
                    showCancleBtn: true,
                    confirmFn: function (r) {
                        getAvatarList();
                    },
                    cancelFn: function (r) {
                        store.commit("set_showConfirmTag", false);
                    }

                });
            });

        }
        //選擇系統頭像
        let logo;
        const selectAvatar = (inx) => {
            logo = avatarArray.value[inx].logo;
            avatarIndex.value = inx;
        }
        //確認購買選擇的頭像
        const buyAvatar = () => {
            common.confirm({
                type: 'warn',
                title: langTxt[lang.value].center[18],
                text: langTxt[lang.value].center[23],
                btnTxt: langTxt[lang.value].confirm.btnTxt[1],
                showCancleBtn: true,
                confirmFn: function () {
                    if (userFinance.goldCoins < modifyUserLogoCardInfo.value) {
                        toastr['error']('Not enough gold');
                        return;
                    }
                    common.showLoading('buyAvatar');
                    const sendUrl = common.userApi.requestUrl + '/user_api/user_modify_logo_cards';
                    const sendHeader = common.buildHeaders('form');
                    const sendData = common.buildSendData({ logo: logo });
                    axios.put(sendUrl, sendData, { headers: sendHeader }).then((r) => {
                        if (r.data.code == 0) {
                            //關閉對話框和頭像列表
                            showAvatarListUI.value = false;
                            //更新用戶信息
                            common.updateUserInfo();
                        }
                        else {

                        }
                    }).catch((e) => {
                        toastr['error'](e);
                    }).finally(() => {
                        common.hideLoading('buyAvatar');
                    });
                },
                cancelFn: function () {
                    store.commit("set_showConfirmTag", false);
                }

            });
        }
        //滾動到底部，繼續加載頭像
        const handleScroll = (x) => {
            if (x == 'bottom') {
                getAvatarList();
            }
        }
        //關閉頭像列表
        const closeAvatarList = () => {
            showAvatarListUI.value = false;
            avatarArray.value = [];
            canLoadAvatar = true;
            loadingTag = false;
        }

        //是否顯示輸入暱稱UI
        let showInputNickUI = ref(false);
        //新暱稱
        let newNickname = ref('');
        //修改呢稱，購買改名卡
        const buyModifyNickCard = () => {
            //名字限定4-12.中文算兩字
            let newNickLength = common.chkHalf(newNickname.value);
            if (newNickLength < 2 || newNickLength > 6) {
                toastr['error']('New nick must be larger than 2 and smaller than 6.')
                return;
            }
            const sendUrl = common.userApi.requestUrl + '/user_api/user_modify_nick_cards';
            const sendHeader = common.buildHeaders('form');
            const sendData = common.buildSendData({ 'nick': newNickname.value });
            common.showLoading('buyModifyNickCard');
            axios.put(sendUrl, sendData, { headers: sendHeader }).then((r) => {
                console.log('--lb', r)
                showInputNickUI.value = false;
                if (r.data.code == 0) {
                    //更新用戶信息
                    common.updateUserInfo();
                }
                else {
                    console.error(r.data.msg);
                    toastr['error'](r.data.msg)
                }
            }).catch((e) => {
                console.error(e);
            }).finally(() => {
                common.hideLoading('buyModifyNickCard');
            })
        }
        //確認是否付費修改暱稱
        const confirmEditNick = () => {
            getUserFinance().then((r) => {
                common.confirm({
                    type: 'info',
                    title: langTxt[lang.value].center[21],
                    text: langTxt[lang.value].center[22] + modifyNickCardInfo.value + ' ' + modifyNickCardInfo.type,
                    btnTxt: langTxt[lang.value].confirm.btnTxt[1],
                    showCancleBtn: true,
                    confirmFn: function (r) {
                        if (userFinance.goldCoins < modifyNickCardInfo.value) {
                            toastr['error']('Not enough gold');
                            return;
                        }
                        showInputNickUI.value = true;
                    },
                    cancelFn: function (r) {
                        store.commit("set_showConfirmTag", false);
                    }

                });
            });

        }

        let showMobileDetailTag = ref(false);

        onMounted(() => {
            if (userInfo) {
                getUserFinance();
            }
        });

        return {
            common,
            showMobileDetailTag,
            langTxt,
            lang, uid, userInfo, userFinance, walletLoginName, loginAndGetuid, showCompleteWalletUI, bindWalletUI, resetPW, confirmEditAvatar, confirmEditNick, showInputNickUI, newNickname, buyModifyNickCard, sysConfig, showAvatarListUI, avatarArray, avatarIndex, selectAvatar, buyAvatar, handleScroll, closeAvatarList
        };
    },
});